.wrapper {
  margin-top: 10vh; /*allow us to push the login-form to ~half of the viewport height*/
}

/* Chop the subject line after two lines and add 3 dots to the end */
.subject-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.loading-card-height {
  height: 269px;
}

/* Makes the top toolbar Glassmorphism-y */
/* ion-toolbar {
  --opacity: 0.75;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
} */
