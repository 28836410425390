@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mic-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

input[type='range'] {
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
  background: rgb(134, 158, 216);
  transition: background 0.2s ease;
  cursor: pointer;
}

.card-shadow {
  box-shadow: rgba(50, 50, 43, 0.2) 0px 30px 50px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.2) 0px -2px 6px 0px inset;
}
